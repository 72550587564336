import { motion } from "framer-motion";
import * as React from "react";

import * as shortStyles from "../../templates/short/short.module.scss";

import shortIcon from "../../../content/image/short-icon.svg";

import classes from "../../utils/classes";
import Meta from "../../components/meta";
import { StaticImage } from "gatsby-plugin-image";

const Newsletter21 = () => {
  return (
    <motion.div
      style={{
        translateX: "-1%",
      }}
      initial={{ opacity: 0 }}
      variants={{
        enter: {
          translateX: 0,
          opacity: 1,
          transition: {
            duration: 0.4,
          },
        },
        exit: {
          opacity: 0,
          translateX: "-1%",
          transition: {
            duration: 0.2,
          },
        },
      }}
    >
      <Meta title={`Adventure Squad | Newsletter 21`} isPost />
      <section className={classes("section", shortStyles.header)}>
        <div>
          <img src={shortIcon} alt="" width={24} height={24} />
          <p className={classes("h5", shortStyles.header__category)}>
            Adventure Squad Newsletter #21
          </p>
        </div>
      </section>
      <section className={classes("section", shortStyles.content)}>
        <p>
          <b>Hello all,</b>
        </p>
        <p>
          Here is my newsletter, with much delay. A big thanks to those who
          reached out to remind me to write it. I appreciate hearing from you
          all through the newsletter and I hope we can continue this tradition.
          I also need to share what I have been up to lately. I felt
          apprehensive writing about my last few months. They have been a bit
          more challenging than most due to social isolation. But as the
          pandemic restrictions lifted, I felt less isolated and more confident
          writing this newsletter.
        </p>
        <figure style={{ textAlign: "center" }}>
          <StaticImage src="../../../content/image/newsletter21/PXL_20210501_025438383.jpg" />
          <figcaption>My new appartment</figcaption>
        </figure>
        <p>
          Since my last update, I have moved to a new apartment. While this is
          not the first time I am living in a place on my own, this was still a
          bit of a change given the state of pandemic restrictions. It has been
          an adjustment to learn to be fully on my own for a long time. I will
          not say it was smooth the whole way, but I am slowly adapting to this
          new lifestyle.
        </p>
        <figure style={{ textAlign: "center" }}>
          <StaticImage src="../../../content/image/newsletter21/PXL_20210509_042031515.jpg" />
          <figcaption>
            My place comes with a projector screen for movie nights!
          </figcaption>
        </figure>
        <p>
          One of my hobbies/interests is in decorating/planning my interior
          space. My new place has been great for that. I have attached a few
          pictures of my apartment and some of my house plants—proud to say I
          have not killed any plants, but it is a good thing that the plants I
          got are also quite hardy!
        </p>
        <figure style={{ textAlign: "center" }}>
          <StaticImage src="../../../content/image/newsletter21/PXL_20210714_030508145.jpg" />
          <figcaption>Really happy with my work setup</figcaption>
        </figure>
        <p>
          Over the winter and spring, I did a bit of hiking and crosscountry
          skiing as a way to get outside. While we were all isolated, I still
          had regular hangouts with Kyle and my old roommates Leah and Corwin.
        </p>
        <figure style={{ textAlign: "center" }}>
          <StaticImage src="../../../content/image/newsletter21/PXL_20210307_193404218.MP.jpg" />
          <figcaption>
            Hiking in Golden Ears, the snow was definitely not on the
            forecast...
          </figcaption>
        </figure>
        <figure style={{ textAlign: "center" }}>
          <StaticImage src="../../../content/image/newsletter21/received_337937874127694.jpg" />
          <figcaption>Crosscountry skiing in Cypress</figcaption>
        </figure>

        <figure style={{ textAlign: "center" }}>
          <StaticImage src="../../../content/image/newsletter21/PXL_20210502_165850014.jpg" />
          <figcaption>
            Hiking the Chief with my sister and my old roommates
          </figcaption>
        </figure>
        <p>
          In May, I considered whether to move to Germany to continue working
          for my current jobs or look for an opportunity elsewhere. Around the
          same time, I was offered an interview for a role in front-end
          development at Amazon in Vancouver. I successfully passed the
          interview and was offered a Front-end Engineer position on the Route
          53 team at AWS. I took up on the offer! It was bittersweet to say
          goodbye to my old colleagues. They were a great team to work with over
          the last year despite the timezone difference. I hope to soon travel
          to Munich and Mannheim to meet them in person!
        </p>
        <figure style={{ textAlign: "center" }}>
          <StaticImage src="../../../content/image/newsletter21/parkdepot.png" />
          <figcaption>
            Leaving ParkDepot get-together. For my departure, they offered me a
            3 hour course on "surviving in bear country" 😂
          </figcaption>
        </figure>
        <p>
          As restrictions lifted, I started doing more things outdoors. In late
          May, I signed up for a month-long sea-kayaking course in Jericho. This
          course was loads of fun. The primary learning outcome was to learn how
          to recover if your kayak flipped at sea, which involved a lot of
          flipping your kayak upside down and being comfortable with being
          upside down underwater and attached to your kayak. It was pretty neat
          to see how our group progressed over the weeks until we were all
          comfortable flipping our kayak upside down away from shore and
          successfully reentering it in deep water.
        </p>
        <figure style={{ textAlign: "center" }}>
          <StaticImage src="../../../content/image/newsletter21/PXL_20210620_205051170.jpg" />
          <figcaption>Kayaking near Pender with Toren</figcaption>
        </figure>
        <p>
          In June, I traveled to the island to meet up with Toren. We camped on
          Pender Island, went kayaking/SUP, played disc golf for the first time
          (for me anyway), and followed up on the Euro football cup that was
          going on at the same time. Regarding the Euro cup, France did not end
          up very far in the tournament. The tournament in itself was especially
          thrilling to watch.
        </p>
        <figure style={{ textAlign: "center" }}>
          <StaticImage src="../../../content/image/newsletter21/PXL_20210619_194545586.jpg" />
          <figcaption>Mount Norman on Pender Island</figcaption>
        </figure>
        <p>
          A couple of hikes defined the month of June and July (the Chief and
          Mount Gardner on Bowen Island). My family came to visit in late July.
          We visited the Sunshine Coast for the first time. Highly recommend
          visiting "Smoke on the Water BBQ Shack" for all you meat lovers out
          there. I had my best pork ribs and salmon burger in recent memory
          there.
        </p>
        <figure style={{ textAlign: "center" }}>
          <StaticImage src="../../../content/image/newsletter21/PXL_20210717_200019215.jpg" />
          <figcaption>Mount Gardner on Bowen Island</figcaption>
        </figure>
        <p>
          My parents sold me their Mazda 3. This car provided a significant
          boost to my mobility. I have been driving it for many summers on the
          island, so I was happy to now own it!
        </p>
        <figure style={{ textAlign: "center" }}>
          <StaticImage src="../../../content/image/newsletter21/P1020691.JPG" />
          <figcaption>My new Mazda 3</figcaption>
        </figure>
        <p>
          In August, I started my new job at Amazon. The work so far has been
          interesting. Amazon is a very peculiar company. I have many videos to
          watch to learn all about their work culture and Leadership principles.
          The team I am on has been very supportive as I onboard in my new role.
          They seem like great colleagues. They plan on returning to the office
          in January, so I still have several more months of working from home.
        </p>
        <figure style={{ textAlign: "center" }}>
          <StaticImage src="../../../content/image/newsletter21/PXL_20210808_221252034.MP.jpg" />
          <figcaption>First time hiking alone, St. Mark Summit</figcaption>
        </figure>
        <figure style={{ textAlign: "center" }}>
          <StaticImage src="../../../content/image/newsletter21/PXL_20210719_012311734.jpg" />
          <figcaption>Oh yeah, that was also a big highlight!</figcaption>
        </figure>
        <p>
          Despite Toren moving to Scotland rather soonish, he still made the
          time to go for a couple of hikes before leaving BC. The definitive
          highlight was hiking to West Lion via the Howe Crest trail in early
          August. It was a fantastic (and challenging) hike that took us 10
          hours and had us run out of water at the 3/4 mark. The views were
          stunning. I will let the pictures speak for themselves:
        </p>
        <figure style={{ textAlign: "center" }}>
          <StaticImage src="../../../content/image/newsletter21/P1020693.JPG" />
          <figcaption>Starting the hike, we already see our goal</figcaption>
        </figure>
        <figure style={{ textAlign: "center" }}>
          <StaticImage src="../../../content/image/newsletter21/P1020708.JPG" />
          <figcaption>Bowen Island in the smoky background</figcaption>
        </figure>
        <figure style={{ textAlign: "center" }}>
          <StaticImage src="../../../content/image/newsletter21/P1020713.JPG" />
          <figcaption>
            This bird has absolutely no fear, was only there for the food
          </figcaption>
        </figure>
      </section>
      <figure style={{ textAlign: "center" }}>
        <StaticImage src="../../../content/image/newsletter21/P1020732.JPG" />
        <figcaption>The Lions</figcaption>
      </figure>
      <section className={classes("section", shortStyles.content)}>
        <figure style={{ textAlign: "center" }}>
          <StaticImage src="../../../content/image/newsletter21/PXL_20210815_194558978.MP.jpg" />
          <figcaption>We're "almost" there!</figcaption>
        </figure>
      </section>
      <figure style={{ textAlign: "center" }}>
        <StaticImage src="../../../content/image/newsletter21/P1020759.JPG" />
        <figcaption>Looking at Vancouver from the base of West Lion</figcaption>
      </figure>
      <section className={classes("section", shortStyles.content)}>
        <figure style={{ textAlign: "center" }}>
          <StaticImage src="../../../content/image/newsletter21/P1020765.JPG" />
          <figcaption>
            Thanking the creek that filled up our waterbottles when we
            completely ran out
          </figcaption>
        </figure>
        <figure style={{ textAlign: "center" }}>
          <StaticImage src="../../../content/image/newsletter21/PXL_20210816_015940463.jpg" />
          <figcaption>We can't feel our legs, but we did it!</figcaption>
        </figure>
        <p>
          And this is it for my update! As more people are joining us in
          Vancouver, I hope we can regroup and catch up more regularly in
          person. I also hope to travel to where our far-flung group members are
          eventually, Calgary, Edmonton, San Francisco, Toronto, and Edinburgh.
        </p>
        <p>
          In terms of things to read, watch, or listen to, here is what has been
          grabbing my attention lately:
        </p>
        <ul>
          <li>
            <a href="https://stevensong.com/alphabetical-list/">
              Steven’s Peak-Bagging Journey;
            </a>{" "}
            the blog of a Metro Vancouver mountaineer. He writes about the many
            peaks he scaled across the continent (over 1000). Some of them are
            pretty insane mountaineering treks.
          </li>
          <li>
            <a href="https://www.youtube.com/channel/UCioZY1p0bZ4Xt-yodw8_cBQ">
              How to drink;
            </a>{" "}
            A Youtube channel focused on the art of mixology, intermixed with
            very eloquent (and slightly tipsy) commentary on current world news.
          </li>
          <li>
            <a href="https://open.spotify.com/album/5DaToG3KvHnUgTPqY4cEbq?si=FbpH5hTvRCqoUVekwc1TGg&dl_branch=1">
              This is the Life, by Amy Macdonald;
            </a>{" "}
            a great album by a Scottish artist. It is not a recent release
            (2007), but I have been listening to it again as of late. All the
            songs on there are great, but the highlights for me are Barrowland
            Ballroom, Mr. Rock and Roll, This is the Life, and Run.
          </li>
        </ul>
        <p>See you all soon,</p>
        <p>
          <b>Quentin</b>
        </p>
      </section>
      <figure style={{ textAlign: "center" }}>
        <StaticImage src="../../../content/image/newsletter21/P1020758.JPG" />
        <figcaption>
          Mount Unnecessary (center), Howe Sound (right), and Vancouver (left)
        </figcaption>
      </figure>
    </motion.div>
  );
};

export default Newsletter21;
